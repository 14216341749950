import * as React from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FiChevronDown } from "react-icons/fi";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import OutsideClickHandler from "react-outside-click-handler";

export class SingleDropdownListWithoutSearchBoot extends React.Component<any, any> {
  private inputElement: any;
  private listDiv: any;

  constructor(props: any) {
    super(props);
    this.inputElement = React.createRef();
    this.listDiv = React.createRef();

    this.state = {
      defaultSelectedName: props.defaultName,
      filterData: props.itemList,
      itemList: props.itemList,
      inputValue: "",
      searchText: props.defaultText,
      controlID: props.controlID,
      isdisable: false,
      isbank: props.isbank,
      disabled: props?.disabled,
      buttonID: props?.id,
    };
    this.handleChange = this.handleChange.bind(this);
    this.resetSerchBox = this.resetSerchBox.bind(this);
  }
  
  handleChange(e: any) {
    const updatedList = this.state.itemList.filter((item: any) => {
      return item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;
    });
    this.setState({ filterData: updatedList, inputValue: e.target.value });
  }

  handleDropdownChange(e: any) {
    this.setState({ inputValue: "", filterData: this.state.itemList, defaultSelectedName: e.name });
    this.props.handleSelectedItem(e.id, this.state.controlID);
  }

  resetSerchBox = () => {
    this.setState({ inputValue: "", filterData: this.state.itemList });
  };

  resetddlItem = (key: any, isdisable: any) => {
    this.setState({ inputValue: "", defaultSelectedName: key, isdisable: isdisable });
  };


  componentDidUpdate(prevProps) {
    
    //if (prevProps?.isRefresh !== this.props?.isRefresh) {
    if (this.state.defaultSelectedName !== this.props.defaultName) {
      this.setState({ inputValue: "", defaultSelectedName: this.props.defaultName });
    }

    if (this.state.itemList?.length === 0 && this.props.itemList?.length > 0) {

      this.setState({
        filterData: this.props.itemList,
        itemList: this.props.itemList, defaultSelectedName: this.props.defaultName
      });
    }

    if (this.state.itemList?.length > 0 && this.props.itemList?.length === 0) {

      this.setState({
        filterData: this.props.itemList,
        itemList: this.props.itemList, defaultSelectedName: this.props.defaultName
      });
    }



  }

  hoverOutEllipsis = () => {
    $(".tooltip").removeClass("show");
  };

  render() {
    const hidSelectModal = (eventKey: any) => {
      if (eventKey === "Digital ACH") {
        if (this.state.isbank === "Yes") {
          this.setState({ inputValue: "", defaultSelectedName: eventKey });
        }
      } else {
        this.setState({ inputValue: "", defaultSelectedName: eventKey });
      }
    };

    const onFocusInput = (eventKey: any) => {
      if (this.inputElement.current !== null) {
        setTimeout(() => {
          this.inputElement.current.focus();
        }, 200);
      }
    };



    return (
      <>
        <OutsideClickHandler
          key="outsideclick"
          onOutsideClick={this.resetSerchBox}
        >
          <Dropdown
            className="more-action bg-blue single-select-imageboot"
            onSelect={hidSelectModal}
            onClick={onFocusInput}
          >
            <Dropdown.Toggle  
            ref={this.props.dropdownToggleRef || null}             
            id={this.state.buttonID ===(undefined || null) ? "Modal-hid" :this.state.buttonID} className="filter-btn" disabled={this.state.disabled ? true : this.state.isdisable}>
              {this.state.defaultSelectedName === this.props.defaultName && (
                <div className="d-flex align-items-center">
                  <div className="txt-area" >
                    <EllipsisWithTooltip placement="bottom">
                      {this.state.defaultSelectedName}
                    </EllipsisWithTooltip>
                  </div>
                  <div className="drop-arrow ml-0 d-flex">
                    <FiChevronDown />
                  </div>
                </div>
              )}
              {this.state.defaultSelectedName !== this.props.defaultName && (
                <div className="d-flex align-items-center">
                  <div className="txt-area">
                    <EllipsisWithTooltip placement="bottom">
                    {this.state.buttonID === "dayFilter" && this.state.defaultSelectedName === "All Transactions" ? "Date Range" : this.state.defaultSelectedName }
                    </EllipsisWithTooltip>
                  </div>
                  <div className="drop-arrow ml-0 d-flex">
                    <FiChevronDown />
                  </div>
                </div>
              )}
              {this.props.calIcon && (
                <div className="cal-icon housekeeping-standalone">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.66675 7.3335H6.00008V8.66683H4.66675V7.3335ZM4.66675 10.0002H6.00008V11.3335H4.66675V10.0002ZM7.33341 7.3335H8.66675V8.66683H7.33341V7.3335ZM7.33341 10.0002H8.66675V11.3335H7.33341V10.0002ZM10.0001 7.3335H11.3334V8.66683H10.0001V7.3335ZM10.0001 10.0002H11.3334V11.3335H10.0001V10.0002Z" />
                    <path d="M3.33333 14.6668H12.6667C13.402 14.6668 14 14.0688 14 13.3335V5.3335V4.00016C14 3.26483 13.402 2.66683 12.6667 2.66683H11.3333V1.3335H10V2.66683H6V1.3335H4.66667V2.66683H3.33333C2.598 2.66683 2 3.26483 2 4.00016V5.3335V13.3335C2 14.0688 2.598 14.6668 3.33333 14.6668ZM12.6667 5.3335L12.6673 13.3335H3.33333V5.3335H12.6667Z" />
                  </svg>
                </div>
              )}
              {this.props.calHomeIcon && (
                <div className="cal-icon housekeeping-standalone">
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                      <path d="M1.3689 6.31577V9.19367H2.05895V13.5087H1.3689V15.6666H2.05895H4.12913H5.50924H7.57942H8.95953H11.0297H12.4098L14.48 15.6673V15.6666H15.1701V13.5087H14.48V9.19367H15.1701V6.31577L8.26947 2L1.3689 6.31577ZM4.12913 13.5087V9.19367H5.50924V13.5087H4.12913ZM7.57942 13.5087V9.19367H8.95953V13.5087H7.57942ZM12.4098 13.5087H11.0297V9.19367H12.4098V13.5087ZM9.64959 6.31577C9.64959 7.10987 9.0313 7.75436 8.26947 7.75436C7.50765 7.75436 6.88936 7.10987 6.88936 6.31577C6.88936 5.52167 7.50765 4.87718 8.26947 4.87718C9.0313 4.87718 9.64959 5.52167 9.64959 6.31577Z" />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width="15.4059" height="16" fill="white" transform="translate(0.406006 0.5)" />
                      </clipPath>
                    </defs>
                  </svg>

                </div>
              )}

            </Dropdown.Toggle>
            
            <Dropdown.Menu>
            <div  className="w-auto" >
              <div className="list-area" ref={this.listDiv}>
                {this.state.filterData.map((item: any, idx: any) => (
                  <>
                    {this.state.isbank === "No" && item.name === "Digital ACH" ? (

                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id={"ehid-list"}>
                            {""}
                          </Tooltip>
                        }
                      >
                        <div className="dropdown-item-text disable">
                          <span className="d-flex align-items-center">
                            <span className="mr-auto disable-text"  >{item.name}</span>
                            <span className="badge orange-bg">Unavailable</span>
                          </span>
                        </div>
                        {/* <Dropdown.Item
                            disabled={false}
                            eventKey={item.name}
                            className="with-badge dropdown-item-text"
                            //onClick={() => this.handleDropdownChange(item)}
                            key={idx}
                            onWheel={() => this.hoverOutEllipsis()}
                          > 
                                <span className="d-flex align-items-center">
                                    <span className="mr-auto disable-text">{item.name}</span>
                                    <span className="badge orange-bg">Unavailable</span>
                                </span>
                          </Dropdown.Item>  */}
                      </OverlayTrigger>
                    ) :
                      <Dropdown.Item
                        disabled={false}
                        eventKey={item.name}
                        onClick={() => this.handleDropdownChange(item)}
                        key={idx}
                        onWheel={() => this.hoverOutEllipsis()}
                      >
                        <EllipsisWithTooltip placement="bottom">
                          {item.name}
                        </EllipsisWithTooltip>
                      </Dropdown.Item>
                    }
                  </>

                ))}
              </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </OutsideClickHandler>
      </>
    );
  }
}