import React from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  Container,
  Dropdown,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import { Utils } from "../../Common/Utilis";
import { CustomerService } from "../../Common/Services/CustomerService";
import { CustomerSearch } from "../../Common/Components/CustomerSearch";
import { FaArrowUpFromBracket, FaCircleMinus, FaCirclePlus, FaEllipsis } from "react-icons/fa6";
import { MdOutlineContentPasteGo } from "react-icons/md";
import { resolve, reject } from "q";
import "../../App.scss";
// import "../Masters/customer.scss";
import { ConfirmationModal } from "../../Common/Components/ConfirmationModal";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { FiChevronDown } from "react-icons/fi";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ScrollContainer from "react-indiana-drag-scroll";
import { CommentBox } from "../../Common/Components/CommentBox";
import { SingleDropdownListWithoutSearch } from "../../Common/Components/SingleDropdownListWithoutSearch";
let routeLeavingGuard: any = null;
let requestInProcess = false;
export class COAMasterSlidout extends React.Component<any, any> {
  private customerSearch: any;
  private parentScroll: any;

  constructor(props: any) {
    super(props);
    console.log("propsss",this.props);
    this.parentScroll = React.createRef();
    this.customerSearch = React.createRef();
    this.state = {
      isCadd:this.props.isCadd==undefined ? false : this.props.isCadd,
      customercode:"",
      selectedCustomer:"",
      selectOptions: [
        {
          id: 'Active', name: 'Active'
        },
        { id: 'Inactive', name: 'Inactive' }

      ],
      selectAccountOptions: [
        { id: 'Asset', name: 'Asset' },
        { id: 'Liability', name: 'Liability' },
        { id: 'Equity', name: 'Equity' },
        { id: 'Revenue', name: 'Revenue' },
        { id: 'Expense', name: 'Expense' }
      ],
      formData: {
        coa: this.props.coainfo.coa || "",
        name:this.props.coainfo.name || "",
        accountType:this.props.coainfo.accountType || "Select Option",
        status:this.props.coainfo.status || "Active",
        mainGroup:this.props.coainfo.mainGroup || "",
        subGroup:this.props.coainfo.subgroup || "",
      },
    };

  }

  updateState = (e: any, index: any, type: any, txtID) => {
    if (this != undefined) {
      let isStateChanged = true;
      let isSaveOpen = true;
      let start = e.target?.selectionStart;
      let end = e.target?.selectionEnd;
      let customerContact = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.customerContact))
      );
      if (type === "firstname") {

        e.preventDefault();

        customerContact[index].firstname = e.target.value;

        this.setState({ customerContact, isStateChanged, isSaveOpen }, () => {
          
          $("input[id^='txtfirstname" + index + "txt']").focus();

          let txtdesc = $("input[id^='txtfirstname" + index + "txt']")[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }
      if (type === "lastname") {

        e.preventDefault();

        customerContact[index].lastname = e.target.value;

        this.setState({ customerContact, isStateChanged, isSaveOpen }, () => {
          $("input[id^='txtlastname" + index + "txt']").focus();

          let txtdesc = $("input[id^='txtlastname" + index + "txt']")[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }
      if (type === "title") {

        e.preventDefault();

        customerContact[index].title = e.target.value;

        this.setState({ customerContact, isStateChanged, isSaveOpen }, () => {
          $("input[id^='txttitle" + index + "txt']").focus();

          let txtdesc = $("input[id^='txttitle" + index + "txt']")[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }
      if (type === "phone") {

        e.preventDefault();

        customerContact[index].phoneno = e.target.value;

        this.setState({ customerContact, isStateChanged, isSaveOpen }, () => {
          $("input[id^='txtphone" + index + "txt']").focus();

          let txtdesc = $("input[id^='txtphone" + index + "txt']")[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }
      if (type === "email") {

        e.preventDefault();

        customerContact[index].email = e.target.value;

        this.setState({ customerContact, isStateChanged, isSaveOpen }, () => {
          $("input[id^='txtemail" + index + "txt']").focus();

          let txtdesc = $("input[id^='txtemail" + index + "txt']")[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }

      if (type === "IsDefault") {
        e.preventDefault();
        
        if(e.target.checked)
        {
          
          customerContact
          .filter((item) => item.id !== customerContact[index].id)
          .map((item, index) => {
            item.isDefault =false;
            return item;
          });
        customerContact[index].isDefault =true;

       

         
         
        }
      else
      {
        customerContact[index].isDefault =false;
      }
        this.setState({ customerContact, isStateChanged,isSaveOpen }, () => {
        
         
        });
      }
    }
  };



  saveItem = () => {

    if (requestInProcess == true) {
      toast.error("Subsequent request has been made.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }

    this.setState({ isSaveSpinner: true });
    requestInProcess = true;
    debugger;
    if(this.state.customerContact.length!=0)
    {
    let contactdata=this.state.customerContact.filter(o=>o.firstname.trim()!="");
    this.state.formData.customerContact = contactdata;
    }
    CustomerService.SaveCustomerData(this.state.formData)
      .then( (result: any | null) => {


        if (result !== null) {
debugger;
          if (result.customer_id != "" || result.customer_id != null) {
           if(this.props.IsActive !=undefined )
           {
            debugger;
            let custommerSelected: any = [];
            custommerSelected.company_name =this.state.formData.company_name;
            custommerSelected.address =this.state.formData.address;
            custommerSelected.customer_id =result.customer_id;
            this.state.formData.customer_id= result.customer_id;
            custommerSelected.customercode = result.customercode;
            
            if(this.state.customerContact.length>0)
            {
            custommerSelected.firstname=this.state.customerContact[0].firstname;
            }
            else

            {
              custommerSelected.firstname=result.firstname;
            }
            
            custommerSelected.address= result.address;
            custommerSelected.apartment= result.apartment;
            custommerSelected.city= result.city;
            custommerSelected.state= result.state;
            custommerSelected.zipcode= result.zip;
            custommerSelected.country= result.country;
            custommerSelected.customerAlias= result.companyAlias;
            // this.customerSearch?.current.handleSelectFromParent(
            //   custommerSelected
            // );

            this.setState({custommerSelected:custommerSelected},()=>
            {
              
            this.props.closeSlideOut(custommerSelected);
           // this.props.closeSlideOut(this.state?.shouldReloadMainPage);
            }


            );

            
            // this.props.handleSelectFromParent(
            //   custommerSelected
            // );
         
           //this.closeSlideOut2();
         // this.customerSearch.current.alertmessage();
           }
            let requestlist: any = {};

            if(result.customer_id!=0)
            {

              toast.success("Customer updated successfully.",
                {
                  position: toast.POSITION.BOTTOM_RIGHT,
                }
              );
            }
            else
            {
            toast.success("Customer created successfully.",
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
          }

          } else if (result.message?.toLowerCase() === "vendorexists") {
            this.setState({ loader: false });
            toast.error("Customer name already exists.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else if (result.message?.toLowerCase() === "samecontractexist") {
            this.setState({ loader: false });
            toast.error("Same contract exists for this EHID.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else {
            toast.error(result.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
        this.setState({ isSpinner: false,
          selectedCustomer: this.state.formData.company_name,
          selectedCustomercode : result.customercode=="" ? "" : " (" + result.customercode + ")", 
iscadd:true,
         });
        requestInProcess = false;
       // this.closeCustomerSlideOut();
       this.setState({ isSpinner: false,isSaveSpinner:false});
        resolve();
      })
      .catch((error) => {
        this.setState({ isSaveSpinner: false });
        requestInProcess = false;
        reject();
      }
      );
  };



  closeCustomerSlideOut = () => {
    this.props.closeSlideOut(this.state?.shouldReloadMainPage);
  };

  handleSelectedItem1 = (control: any, id: any) => {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        status: id
      }
    }));
  };

  handleSelectedItem = (control: any, id: any) => {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        accountType: id
      }
    }));
  };

  handleChange = (event: any, FieldType: string) => {

    let isStateChanged = true;

    let formData = { ...this.state.formData };

    if (FieldType === "coa") {
      formData.coa = event.target.value;
    }
    else if (FieldType === "name") {
      formData.name = event.target.value;
    }
    else if (FieldType === "mainGroup") {
      formData.mainGroup = event.target.value;
    }
    else if (FieldType === "subGroup") {
      formData.subGroup = event.target.value;
    }
  
    this.setState({ formData, isStateChanged, isSpinner: false }, () => {

    });
  };

  render() {
    const {


    } = this.state;


    return (
      <section>
        <div className="details-drawer"
          style={{ minWidth: "75%", width: "992px", maxWidth: "100%" 
          }}
        >
          <ToastContainer
            containerId={"userDetailSlideoutcontainer"}
            autoClose={3000}
          />

          <div className="d-flex">
            {!this.state.isOpenViewer && (
              <div
                className="back-drop1  viewer-panel1 opacity "
                onClick={() => this.closeCustomerSlideOut()}
              ></div>
            )}

            <Container
              fluid

              className="body-sec viewer-panel back-drop p-0 "
              style={{ minWidth: "75%", width: "992px", maxWidth: "100%" , zIndex: "1000"}}

            >
              <div
                className="container-fluid py-1 bg-white d-flex align-items-center  border-bottom round-4"
              >
                <div className="divheader">
                  <h5 className="my-title">
                    {this.state.formData.coa === "" ? <span>New COA </span> : <span>COA - {this.state.formData.coa}</span>}

                  </h5>


                  <div className="header-popout ml-auto">





                    <div className="container text-center">
                      <div className="row align-items-start">
                        <div className="col">
                        </div>

                        <div className="col">
                          <button
                            type="button"
                            className="btn btn-primary cross"
                            onClick={this.closeCustomerSlideOut}
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                                fill="#ffffff"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="scroll"
              >

                <section className="pb-3">

                  <div className="row g-3">

                    <div className="col-12 col-md-6" >
                      <Form.Group className="rm-bottom-margin" controlId="coa">
                        <Form.Label>COA</Form.Label>
                        <div className="form-outline w-50">
                          <Form.Control type="text" id="txtCustomer"
                            value={this.state.formData.coa}
                            autoComplete='off'
                            disabled={false}
                            onChange={(event: any) =>
                            this.handleChange(event, "coa")}
                          />
                        </div>

                      </Form.Group>

                    </div>

                    <div className="col-12 col-md-6">
                    <Form.Group className="rm-bottom-margin" controlId="status">
                      <Form.Label>Status</Form.Label>
                      <div className="single-select type">
                        <Form.Check 
                          type="checkbox"
                          label="Active"
                          checked={this.state.formData.status}
                          onChange={(e) => this.handleSelectedItem1(1, e.target.checked)}
                          id="status-checkbox"
                        />
                      </div>
                    </Form.Group>
                  </div>


                    <div className="col-12 col-md-12" >
                      <Form.Group className="rm-bottom-margin" controlId="name">
                        <Form.Label> Name</Form.Label>
                        <div className="form-outline w-25">
                          <Form.Control type="text" id="txtCustomer"
                            value={this.state.formData.name}
                            autoComplete='off'
                            disabled={false}
                          //  style= {{ max-width: "600px" }}
                            onChange={(event: any) =>
                              this.handleChange(event, "name")}
                          />
                        </div>

                      </Form.Group>

                    </div>
                   
                    <div className="col-12 col-md-12" >
                      <Form.Group className="rm-bottom-margin" controlId="mainGroup">
                        <Form.Label>Main Group</Form.Label>
                        <div className="form-outline w-25">
                          <Form.Control type="text" id="txtCustomer"
                            value={this.state.formData.mainGroup}
                            autoComplete='off'
                            disabled={false}
                          //  style= {{ max-width: "600px" }}
                            onChange={(event: any) =>
                              this.handleChange(event, "mainGroup")}
                          />
                        </div>

                      </Form.Group>

                    </div>

                    <div className="col-12 col-md-12" >
                      <Form.Group className="rm-bottom-margin" controlId="subGroup">
                        <Form.Label>Sub Group</Form.Label>
                        <div className="form-outline w-25">
                          <Form.Control type="text" id="txtCustomer"
                            value={this.state.formData.subGroup}
                            autoComplete='off'
                            disabled={false}
                            onChange={(event: any) =>
                              this.handleChange(event, "subGroup")}
                          />
                        </div>

                      </Form.Group>

                    </div>


                    <div className="col-12 col-md-12" >
                    <Form.Group className="rm-bottom-margin" controlId="status">
                    <Form.Label >Account Type</Form.Label>
                       <div className="single-select type">
                         <Form.Label>
                          <SingleDropdownListWithoutSearch
                          itemList={this.state.selectAccountOptions}
                          handleSelectedItem={this.handleSelectedItem.bind(this, 1)}
                          defaultItem={this.state.formData.accountType}
                          defaultText={"Select Account Type"}
                          defaultName={this.state.formData.accountType}
                          controlID="1"
                          id={"ddlselected"}
                          key="id"       
                        /></Form.Label>
                        
                    </div>
                    </Form.Group>

                    </div>
                 
                  </div>

                </section>
              

                <div className={"footer-section viewer-panel"}
                  style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
                >
                  <div>
                    <div className="d-flex align-items-center justify-content-end height-72">
                      <div className="d-flex align-items-center">
                        {
                          (
                            <>
                              <Button
                                variant="primary"
                                type="button"
                                className="space btn btn-primary btn-discard"
                                onClick={this.closeCustomerSlideOut}
                              >
                                Discard
                              </Button>
                              <Button
                                id="btnSave"
                                variant="primary"
                                type="button"
                                onClick={() => this.saveItem()}

                              >
                                {this.state.formData.customer_id == 0 ? "Create" : "Update"}
                              </Button>



                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </Container>
          </div>

          1

          {(this.state.isSpinner || this.state.isSaveSpinner) && (
            <div className="loader-spinner d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="success" />
            </div>
          )}






        </div>

      </section>
    );
  }
}
