import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import * as React from "react";
import DatePicker from "react-datepicker";
import {
  Container,
  Dropdown,
  Accordion,
  Card,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import { User } from "../../Common/Services/User";
import { SingleSearchDropdownListBootrap } from "../../Common/Components/SingleSearchDropdownListBootrap";
import { DropdownMultiSelect } from "../../Common/Components/DropdownMultiSelect";
import BootstrapTable from "react-bootstrap-table-next";
import { Roles } from "../../Common/Services/RolePermissionSetting";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import { VendorService } from "../../Common/Services/VendorService";
import { Utils } from "../../Common/Utilis";
import _ from "lodash";
import paginationFactory from "react-bootstrap-table2-paginator";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import createDOMPurify from "dompurify";
import chevronRight from "../../Common/Assets/Images/icons/bx-chevron-right.svg";
import moment from "moment";
import { JobTrackerServices } from "../../Common/Services/JobTrackerServices";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { DetailedReport } from "./DetailedReport";
import { ReportsService } from "../../Common/Services/ReportsService";
import { SingleDropdownListWithoutSearch } from "../../Common/Components/SingleDropdownListWithoutSearch";
const { SearchBar } = Search;
let storage = JSON.parse(localStorage.getItem("storage")!);
let currentUtcTime =
  storage === null ? "America/Chicago" : (storage.currentUtcTime as any);

import "react-tabs/style/react-tabs.css";
export class ScheduledReportSlideout extends React.Component<any, any> {
  private parentScroll: any;
  private multiselectChild: any;

  constructor(props: any) {
    super(props);
    this.parentScroll = React.createRef();
    this.getReports = this.getReports.bind(this);
    this.getMyScheduleReportDetails = this.getMyScheduleReportDetails.bind(this);
    this.state = {
      data: [],
      selectedcolumn: "All",
      taboption: "first",
      showDetailedReport: false,
      userList: [],
      roleIds: [],
      mappingData: [],
      userRoles: [],
      selectedRoles: [],
      isDataChanged: false,
      isActionFooter: false,
      formData: {
        reportid: this.props.reportId,
        reportName: this.props.reportName,
        firstrundate: null,
        schedule: "",
        period: "",
        reportUserSchedule: [],
        reportRoleSchedule: []
      },
      mappingColumns: [
        {
          dataField: "id",
          text: "User ID",
          hidden: true,
        },
        {
          dataField: "name",
          text: "User Name",
          sort: true,
        },
      ],
      selectSchedule: [
        {
          id: "Daily",
          name: "Daily",
        },
        { id: "Weekly", name: "Weekly" },
        { id: "Monthly", name: "Monthly" },
        { id: "Yearly", name: "Yearly" },
      ],
      selectPeriod: [
        {
          id: "Daily",
          name: "Daily",
        },
        { id: "Same as run date", name: "Same as run date" },
        { id: "Prior day of run date", name: "Prior day of run date" },
        { id: "Prior month of run date", name: "Prior month of run date" },
        {
          id: "Prior to Prior month of run date",
          name: "Prior to Prior month of run date",
        },
      ],

      users: [],
      dropdownVisible: false,
      staticUsers: ["Alice", "Bob", "Charlie"],
      selectedUser: "",
    };
  }


 

  componentDidMount() {
    this.getReports();
    this.getUsers();
    this.getRoles();
  }

  getMyScheduleReportDetails = () => {
    // debugger;

    let formData = this.state.formData;
    let mappingData = [];
    ReportsService.GetMyScheduleReportDetails(this.props.id)
      .then(async (result: any | null) => {
        if (result !== null) {
        console.log("detailsss",result);
        // formData.reportName = result.reportName;
        // formData.reportid= result.reportid;
        formData.period= result.period;
        formData.schedule= result.schedule;
        formData.reportUserSchedule= result.reportUserSchedule;
        formData.reportRoleSchedule= result.reportRoleSchedule;
        if (result.firstrundate !== "0001-01-01T00:00:00") {
          formData.firstrundate = result.firstrundate;
        }
        if(this.state.userList.length > 0 &&  result.reportUserSchedule.length > 0){
         mappingData = result.reportUserSchedule.map(item => {
          const user = this.state.userList.find(user => user.id === item.userid);
          // console.log("namess",item.userid,this.state.userList,user);
          const name = user ? user.name : 'Unknown';
  
          return {
              id: item.userid,
              name: name
          };
         });
        }


        this.setState({ formData , mappingData });
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ isSpinner: false });
        reject();
      });
  };

  saveItem = () => {
    let formData = { ...this.state.formData };
    console.log("formdata", formData);

    let request: any = {};
    request = Object.assign({}, JSON.parse(JSON.stringify(formData)));

    ReportsService.UpdateMyScheduleReport(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          toast.success("Report has been added on My Scheduled Reports", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "OrderSlideout",
          });

          this.closeSlideOut();
        } else {
          toast.success("Report name already exists.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "OrderSlideout",
          });
        }
        resolve();
      });

  };

  handleSelectedItem = (selectedUser) => {
    console.log("selected", selectedUser);
    if (selectedUser) {
      const { userList , formData } = this.state; 
      const matchedUser = userList.find(user => user.id === selectedUser);
      
      if (matchedUser) {

        const newScheduleEntry = {
          id: formData.reportid,
          reportSchId: formData.reportid, 
          userid: matchedUser.id,
          tenantid: matchedUser.tenantid,
        };

        const name = matchedUser.name;
  
        this.setState((prevState) => ({
          mappingData: [
            ...prevState.mappingData,
            { id: matchedUser.id, name },
          ],
          formData: {
            ...prevState.formData,
            reportUserSchedule: [
              ...(Array.isArray(prevState.formData.reportUserSchedule) ? prevState.formData.reportUserSchedule : []),
              newScheduleEntry,
            ],
          },
        }));
      } else {
        console.log("User not found in userList");
      }
    }
  };
  


  handleSelectedItem1 = (control: any, id: any) => {
    if (this != undefined) {
      let formData = this.state.formData;

      if (control === "schedule" && id.length > 0) {
        formData.schedule = id;
      } else if (control === "period" && id.length > 0) {
        formData.period = id;
      }
      this.setState({ formData });
    }
  };

  handleInputChange = (field) => (event) => {
    const value = event.target ? event.target.value : event;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [field]: value,
      },
    }));
  };

  getRoles = () => {
    let request: any = {};
    Roles.GetRoleData(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          result.forEach((element) => {
            let item: any = {};
            item.value = element.roleID;
            item.label = element.roleName;
            item.tenantid = element.tenantId;
            item.isChecked = false;
            this.state.userRoles.push(item);
          });
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ isSpinner: false });
        reject();
      });
      this.getMyScheduleReportDetails();

  };

  getUsers = () => {
    User.getAllUsers()
      .then(async (result: any | null) => {
        if (result !== null) {
          result.forEach((element) => {
            let item: any = {};
            item.id = element.user_UniqueID;
            item.name = element.firstName + " " + element.lastName;
            item.tenantid = element.tenantID;
            this.state.userList.push(item);
          });
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ isSpinner: false });
        reject();
      });
      this.getMyScheduleReportDetails();

  };

  getReports = () => {
    // debugger;

    ReportsService.GetReports()
      .then(async (result: any | null) => {
        // debugger;
        if (result !== null) {
          this.setState({ data: result });
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ isSpinner: false });
        reject();
      });
  };

  shownewslideout = () => {
    this.setState({
      showSlideout: true,
    });
  };

  showslideout = () => {
    this.setState({ showSlideout: true, isSpinner: false });
  };

  closeSlideOut = () => {
    this.props.closeSlideOut();
  };

  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      // if (e.target.tagName !== "BUTTON") {
      //   this.setState({
      //     showSlideout: true,
      //   });
      // }
      this.setState({
        showDetailedReport: true,
        reportId: row.reportid,
        reportName: row.reportName,
      });
    },
  };

  handleSelect = (key: string | null) => {
    this.setState({ taboption: key || "first" });
  };


  // updateMultiSelectItem = (selectedItem: any) => {
  //   console.log("yooo",selectedItem);
  //   // this.setState({
  //   //   selectedRoles: selectedItem,
  //   //   isDataChanged: true
  //   //   isActionFooter: true,
  //   // });
  // };

  updateMultiSelectItem = (selectedItem: any) => {  
    const { formData } = this.state;
  
    const selectedItems = selectedItem.map(item => ({
      id: formData.reportid,
      reportSchId: formData.reportid,
      roleId: item.value,
      tenantid: item.tenantid || 0,
    }));
  
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        reportRoleSchedule: [
          selectedItems,
        ],
      },
    }));
  };
  
  
  multiSelectOpen = () => {
    $("#schedulerightpanelbody").animate(
      { scrollTop: $(document).height() },
      1500
    );
  };
  dataChange = () => {};
  render() {
    const customTotal = (from, to, size) => (
      <span className="text-muted">
        Showing {from} to {to} of {size} entries
      </span>
    );
    const options = {
      showTotal: true,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "25",
          value: 25,
        },
        {
          text: "50",
          value: 50,
        },

        {
          text: "All",
          value: this.state.data.length,
        },
      ], // A numeric array is also available. the purpose of above example is custom the text
    };

    const qualitycolumns = [
      {
        dataField: "srno",
        text: "Sr No.",
        hidden: false,
        editable: false,

        headerStyle: { width: "10%" },
        headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
        formatter: (cell: any, row: any, rowIndex: any) => {
          return <div>{rowIndex + 1}</div>;
        },
      },

      {
        dataField: "reportName",
        text: "Report Name",
        sort: true,
        searchable: this.state.isReportName,
        headerStyle: { width: "25%" },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return <div>{row.reportName}</div>;
        },
      },
      {
        dataField: "reportDescription",
        text: "Report Description",
        sort: true,

        headerStyle: { width: "40%" },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return <div>{row.reportDescription}</div>;
        },
      },
      {
        dataField: "reportGroup",
        text: "Report Tag",
        sort: true,
        searchable: this.state.isReporttag,
        headerClasses: "text-start",
        classes: "text-start",
        headerStyle: { width: "30%" },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return <div>{row.reportGroup}</div>;
        },
      },
      {
        dataField: "",
        text: "Action",

        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="btn-group">
              <Dropdown
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="more-action"
              >
                <Dropdown.Toggle
                  className="btn-outline-primary btn btn-primary-white more"
                  id={"dropdown-more" + rowIndex}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey={"comments"}>Add </Dropdown.Item>
                  <Dropdown.Item
                    eventKey={"comments"}
                    onClick={() => {
                      this.setState({
                        showDetailedReport: true,
                        reportId: row.reportid,
                        reportName: row.reportName,
                      });
                    }}
                  >
                    Run Report{" "}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          );
        },
      },
    ];

    const { showSlideout } = this.state;
    const cdate = new Date(
      Utils.convertUtcToTimezoneFormat(new Date(), currentUtcTime)
    );
    const firstDayOfMonth = new Date(cdate.getFullYear(), cdate.getMonth(), 1);
    return (
      <section>
        <div
          className="details-drawer"
          style={{
            minWidth: "75%",
            width: "992px",
            maxWidth: "100%",
          }}
          // className="back-drop1  viewer-panel1 opacity "
        >
          <div
            className="back-drop1  viewer-panel1 opacity "
            style={{ minWidth: "25%", maxWidth: "100%", height: "100vh" }}
            /*  className="back-drop1  viewer-panel1 opacity " */
            onClick={() => this.closeSlideOut()}
          ></div>

          <Container
            fluid
            ref={this.parentScroll}
            className="body-sec viewer-panel back-drop p-2"
            // style={{
            //   minWidth: "50%",
            //   width: "792px",
            //   maxWidth: "100%",
            //   minHeight: "100%",
            //   overflowY: "auto",
            // }}
            style={{
              minWidth: "50%",
              width: "791px",
              maxWidth: "100%",
              height: "87%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="page-heading underline d-flex ">
              <div className="mx-3 border rounded p-2">
                {this.props.reportName}
              </div>
              <div className="d-flex flex-row ml-auto">
                <button
                  type="button"
                  className="btn btn-primary cross"
                  onClick={this.closeSlideOut}
                  style={{ marginLeft: "10px" }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                      fill="#ffffff"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="scroll" style={{ flex: 1, overflowY: "auto", paddingBottom: "20px" }}>
            <section>
                <div className="col g-3 m-3">
                  <Form.Label
                    className="font-semibold mb-3"
                    style={{ fontSize: "16px" }}
                  >
                    Report Type
                  </Form.Label>

                  <div className="col-md-6">
                    <div className="row g-3 align-items-center">
                      <div className="col-md-6">
                        <Form.Label className="font-semibold">
                          Report Name
                        </Form.Label>
                      </div>
                      <div className="col-md-6">
                        <Form.Group controlId="externalCustomerPO">
                          <Form.Control
                            // tabIndex={this.state.tabEnable}
                            id="txtExternalCustomerPO"
                            type="text"
                            placeholder="Enter PO No."
                            value={this.state.formData.reportName}
                            onChange={this.handleInputChange("reportName")}
                            autoComplete="off"
                            disabled={false}
                            title=""
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 mt-4">
                    <div className="row g-3 align-items-center">
                      <div className="col-md-6">
                        <Form.Label className="font-semibold">
                          First Run Date
                        </Form.Label>
                      </div>
                      <div className="col-md-6">
                        <Form.Group controlId="vendorName">
                          <div className="parent my-data mb-2">
                            <div className="date-picker calender-picker">
                              <DatePicker
                                id="startDate"
                                selected={
                                  this.state.formData.firstrundate === null ||
                                  this.state.formData.firstrundate === undefined
                                    ? cdate
                                    : this.state.formData.firstrundate === ""
                                    ? cdate
                                    : new Date(this.state.formData.firstrundate)
                                }
                                onChange={(date) =>
                                  this.handleInputChange("firstrundate")(date)
                                }
                                tabIndex={this.state.tabEnable}
                                className="form-control px-0 text-center"
                                placeholderText={"MM/DD/YY"}
                                style={{ width: "30%" }}
                                dateFormat="MM/dd/yy"
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <hr className="my-4" />

              <section>
                <div className="col g-3 m-3">
                  <Form.Label
                    className="font-semibold mb-3"
                    style={{ fontSize: "16px" }}
                  >
                    Scheduling
                  </Form.Label>

                  <div className="col-md-6">
                    <div className="row g-3 align-items-center">
                      <div className="col-md-6">
                        <Form.Label className="font-semibold">
                          Schedule
                        </Form.Label>
                      </div>
                      <div className="col-md-6">
                        <Form.Group controlId="schedule">
                          <SingleDropdownListWithoutSearch
                            itemList={this.state.selectSchedule}
                            handleSelectedItem={this.handleSelectedItem1.bind(
                              this,
                              "schedule"
                            )}
                            defaultItem={this.state.formData.schedule}
                            defaultText={"Select Filter"}
                            defaultName={
                              this.state.formData.schedule || "Select Filter"
                            }
                            controlID="1"
                            id={"ddlselected"}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 mt-4">
                    <div className="row g-3 align-items-center">
                      <div className="col-md-6">
                        <Form.Label className="font-semibold">
                          Period
                        </Form.Label>
                      </div>
                      <div className="col-md-6">
                        <Form.Group controlId="period">
                          <SingleDropdownListWithoutSearch
                            itemList={this.state.selectPeriod}
                            handleSelectedItem={this.handleSelectedItem1.bind(
                              this,
                              "period"
                            )}
                            defaultItem={this.state.formData.period}
                            defaultText={"Select Filter"}
                            defaultName={
                              this.state.formData.period || "Select Filter"
                            }
                            controlID="1"
                            id={"ddlselected"}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <hr className="my-4" />
              <section>
                <div className="col g-3 m-3">
                  <Form.Label
                    className="font-semibold mb-3"
                    style={{ fontSize: "16px" }}
                  >
                    Recipients
                  </Form.Label>
                  <div className="col-md-6 my-3">
                    <div className="row g-3 align-items-center">
                      <div className="col-md-6">
                        {/* <Form.Label className="font-semibold">
                          Select Roles
                        </Form.Label> */}
                      </div>
                      <div
                         // className="col-sm-9 selectRolesMultiSelect"
                        //  style={{ maxWidth: "fit-content" }}
                        >
            {this.state.userRoles.length > 0 && (
            <div className="col-sm-9 selectRolesMultiSelect" style={{ maxWidth: "fit-content" , marginBottom:'30px'}}>
            <DropdownMultiSelect
                    data={this.state.userRoles}
                    label="label"
                    value="value"
                    placeHolder="Select Role"
                    filtertitle="Search..."
                    updateMultiSelectItem={this.updateMultiSelectItem}
                  />
                  </div>
            )}
               
                        </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <BootstrapTable
                      id="tblMapping"
                      keyField="id" // Use id as the unique key
                      data={this.state.mappingData}
                      columns={this.state.mappingColumns}
                    />

                    <div>
                      <SingleSearchDropdownListBootrap
                        tabIndex={this.state.tabEnable}
                        itemList={this.state.userList}
                        //  handleSelectedItem={this.handleSelectedItem.bind(row, 1 + '_' + rowIndex)}
                        defaultItem={"Add User"}
                        handleSelectedItem={this.handleSelectedItem}
                        defaultText={"Search User Name"}
                        defaultName={"Add User"}
                        controlID="1"
                        //  id={"ddlUser-" + rowIndex + "ddl"}
                        //onTAB={this.onTAB.bind(this, "ddlprocess-" + rowIndex + "ddl", rowIndex)}
                        // disabled={disable}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div
              className={"footer-section viewer-panel mt-3"}
              style={{ minWidth: "51.5%", width: "700px", maxWidth: "100%" }}
            >
              <div className="d-flex gap-3 align-items-center justify-content-end height-72">
                <div className="d-flex gap-3 align-items-center">
                  {/* {this.state.isStateChanged && ( */}
                  <>
                    <Button
                      variant="primary"
                      type="button"
                      className="space btn btn-primary btn-discard"
                      onClick={this.closeSlideOut}
                    >
                      Discard
                    </Button>
                    <Button
                      id="btnSave"
                      variant="primary"
                      type="button"
                      onClick={() => this.saveItem()}
                    >
                      {"Save"}
                    </Button>
                  </>
                  {/* )} */}
                </div>
              </div>
            </div>
          </Container>

          {(this.state.isSpinner || this.state.isSaveSpinner) && (
            <div className="loader-spinner d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="success" />
            </div>
          )}

          {/* {this.state.showDetailedReport && <DetailedReport closeSlideout={()=>{this.setState({showDetailedReport:false});}} />} */}
          {/* <DetailedReport
            show={this.state.showDetailedReport}
            reportId={this.state.reportId}
            ReportName={this.state.reportName}
            onHide={() => {
              this.setState({ showDetailedReport: false });
            }}
          /> */}
        </div>
      </section>
    );
  }
}
