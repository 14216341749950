import React, { Component } from 'react';

interface ReportViewerProps {
  reportUrl: string;
}

interface ReportViewerState {}

class ReportViewer extends Component<ReportViewerProps, ReportViewerState> {
  render() {
    return (
      <div className="reportViewerContainer">
      <iframe
        src={this.props.reportUrl}
        title="Report Viewer"
        className='iframe'
        // width="100%"
        // height="100vh"
        // style={{ border: 'none' }}
      />
      </div>
    );
  }
}

export default ReportViewer;
