import { IApiConfig } from "../Contracts/IApiConfig";

const hostname = window && window.location && window.location.hostname;
const app_Root = {} as IApiConfig;

if (hostname.indexOf("localhost") > -1) {
 
 
  app_Root.backendHost ="https://localhost:44385/api";  
    //app_Root.backendHost ="https://demogcw.azurewebsites.net/api";  

 

} else {
   
 

 

app_Root.backendHost ="https://dev-cimmple.azurewebsites.net/api";  


//app_Root.backendHost ="https://workflowapi20240717221933.azurewebsites.net/api";  
 
}

export const API_ROOT = app_Root;
