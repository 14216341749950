import axios from "axios";
import Instense from "./Axios-config";
import { API_ROOT } from "./Api-config";
 
export class ReportsService {

public static GetReports = async (): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let request: any = {};
  request.tenantid = tenantID;
  const url = `/Report/GetReports`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};


public static GetSubReports = async ( reportgroup: any): Promise<any | null> => {
//public static GetSubReports = async (reportgroup : any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let request: any = {};
  request.tenantid = tenantID;
  request.reportgroup=reportgroup;
  const url = `/Report/GetSubReports`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

 
public static GetMyReport = async (): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let request: any = {};
  request.tenantid = tenantID;
  const url = `/Report/GetMyReport`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};


public static GetReportFilterSetting = async (reportid : any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let request: any = {};
  request.tenantid = tenantID;
  request.reportid = reportid;
  const url = `/Report/GetReportFilterSetting`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static GetFilterDropdown = async (request : any): Promise<any | null> => {
  const url = `/Report/GetFilterDropdown`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static GetDropdownData = async (request : any): Promise<any | null> => {
  const url = `/Report/GetDropdownData`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static GetMyReportDetails = async ( id: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let request: any = {};
  request.tenantid = tenantID;
  request.id=id;
  const url = `/Report/GetMyReportDetails`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};


public static SaveMyReport = async (request: any): Promise<any[] | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);

  request.tenantID = tenantID;
  const url =`/Report/SaveMyReport`;
  return Instense.post(url,request).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static SeachAllData = async (request: any): Promise<any[] | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);

  request.tenantID = tenantID;
  const url =`/Report/SearchAllData`;
  return Instense.post(url,request).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};


public static UpdateMyScheduleReport = async (request: any): Promise<any[] | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);

  request.tenantID = tenantID;
  const url =`/Report/UpdateMyScheduleReport`;
  return Instense.post(url,request).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static GetMyScheduleReportDetails = async ( id: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let request: any = {};
  request.tenantid = tenantID;
  request.id=id;
  const url = `/Report/GetMyScheduleReportDetails`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};



public static GetMyScheduledReports = async (): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let request: any = {};
  request.tenantid = tenantID;
  const url = `/Report/GetMyScheduledReports`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static UpdateMyReport = async (request: any): Promise<any[] | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);

  request.tenantID = tenantID;
  const url =`/Report/UpdateMyReport`;
  return Instense.post(url,request).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};
public static RunMyReport = async (request: any): Promise<any[] | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);

  request.tenantID = tenantID;
  const url =`/Report/RunMyReport`;
  return Instense.post(url,request).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};


public static GetCustomerDataReport = async (request: any): Promise<any[] | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);

  request.tenantID = tenantID;
  const url =`/Report/GetCustomerDataReport`;
  return Instense.get(url,request).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static DeleteMyReport = async (request: any): Promise<any[] | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);

  request.tenantID = tenantID;
  const url =`/Report/DeleteMyReport`;
  return Instense.post(url,request).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static DeleteMyScheduleReport = async (request: any): Promise<any[] | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);

  request.tenantID = tenantID;
  const url =`/Report/DeleteMyScheduleReport`;
  return Instense.post(url,request).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

}