import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import * as React from "react";
import {
  Container,
  Dropdown,
  Accordion,
  Card,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import { VendorService } from "../../Common/Services/VendorService";
import { Utils } from "../../Common/Utilis";
import _ from "lodash";
import paginationFactory from "react-bootstrap-table2-paginator";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import createDOMPurify from "dompurify";
import chevronRight from "../../Common/Assets/Images/icons/bx-chevron-right.svg";
import moment from "moment";
import { JobTrackerServices } from "../../Common/Services/JobTrackerServices";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { DetailedReport } from "./DetailedReport";
import { ReportsService } from "../../Common/Services/ReportsService";
import { SingleDropdownListWithoutSearch } from "../../Common/Components/SingleDropdownListWithoutSearch";
const { SearchBar } = Search;

import "react-tabs/style/react-tabs.css";
export class ReportsSlideout extends React.Component<any, any> {
  private parentScroll: any;

  constructor(props: any) {
    super(props);
    this.parentScroll = React.createRef();
    this.getReports = this.getReports.bind(this);
  this.handleSelectedItem1=this.handleSelectedItem1.bind(this);
    this.state = {
      data: [
       
      ],
      expandedRows: [],
      ispopupshow:false,
      MyReportName:"",
      selectedcolumn: "All",
      taboption: "first",
      showDetailedReport: false,
      selectOptions: [
        {
          id: "All",
          name: "All",
        },
      
        { id: "Report Tag", name: "Report Tag" },
        { id: "Report Name", name: "Report Name" },
      ],
    };
  }


  componentDidMount() { 
    this.getReports();
  }


  handleSelectedItem1 = (control: any, id: any) => {
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.data))
    );

    let isReporttag = id == "Report Tag" ? true : false;
    let isReportName = id == "Report Name" ? true : false;
    


    if (id == "All") {
      isReporttag = true;
      isReportName = true;
    
    }


    this.setState({
      data: [], selectedcolumn: id, isReporttag: isReporttag, isReportName: isReportName,
    
    },
      () => {
        this.setState({ data: data });
      }

    );

  };
  getReports = () => {

    debugger;
    
    ReportsService.GetReports()
      .then(async (result: any | null) => {
debugger;
        if (result !== null) {

        


          this.setState({ data: result });


        }
        resolve();

      })
      .catch((error) => {
        this.setState({ isSpinner: false });
        reject();
      });
  };



  shownewslideout = () => {
    this.setState({
      showSlideout: true,
    });
  };

  showslideout = () => {
    this.setState({ showSlideout: true, isSpinner: false });
  };

  closeSlideOut = () => {
    this.props.closeSlideOut(true);

  };

  closeSlideOut2
  = () => {
    this.setState({ showDetailedReport:false,showSlideout: false});
   
  };

  MyReportSave=()=>{
debugger;
    let saveRequest: any = {};
    saveRequest.id = 0;
    saveRequest.Reportid = this.state.reportId;
    saveRequest.ReportName = this.state.MyReportName;

    ReportsService.SaveMyReport(saveRequest)
    .then(async (result: any | null) => {
debugger;
      if (result !== null) {

      
        toast.success('Report has been added on My Reports', {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "OrderSlideout",
        });

        this.setState({ ispopupshow:false});


      }
      else
      {

        toast.success('Report name already exists.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "OrderSlideout",
        });

      }
      resolve();

    })
    .catch((error) => {
      this.setState({ isSpinner: false });
      reject();
    });

  };

  MyCancel=() =>{

    this.setState({ispopupshow:false});
  };
  handleChange = (e: any, control: any) => {
    //e.preventDefault();

   
    this.setState({ MyReportName: e.target.value });
    
  };

  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      // if (e.target.tagName !== "BUTTON") {
      //   this.setState({
      //     showSlideout: true,
      //   });
      // }
      this.setState({ showDetailedReport: true,reportId:row.reportid,reportName:row.reportName,reportGroup:row.reportGroup });
    },
  };

  handleSelect = (key: string | null) => {
    this.setState({ taboption: key || "first" });
  };

  render() {
    const customTotal = (from, to, size) => (
      <span className="text-muted">
        Showing {from} to {to} of {size} entries
      </span>
    );
    const options = {
      showTotal: true,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "25",
          value: 25,
        },
        {
          text: "50",
          value: 50,
        },

        {
          text: "All",
          value: this.state.data.length,
        },
      ], // A numeric array is also available. the purpose of above example is custom the text
    };

    const qualitycolumns = [
      {
        dataField: "srno",
        text: "Sr No.",
        hidden: false,
        editable: false,
      
        headerStyle: { width: "10%" },
        headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
        formatter: (cell: any, row: any, rowIndex: any) => {
          return <div>{rowIndex + 1}</div>;
        },
      },

      {
        dataField: "reportName",
        text: "Report Name",
        sort: true,
        classes:"cursor-pointer",
        searchable: this.state.isReportName,
        headerStyle: { width: "25%" },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
          
              <div>
             
                  {row.reportName}
                
              </div>
            
          );
        },
      },
      {
        dataField: "reportDescription",
        text: "Report Description",
        sort: true,
       
        headerStyle: { width: "40%" },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
           
              <div>
              
                  {row.reportDescription}
               
              </div>
           
          );
        },
      },
      {
        dataField: "reportGroup",
        text: "Report Tag",
        sort: true,
        searchable: this.state.isReporttag,
        headerClasses: 'text-start',
        classes: 'text-start cursor-pointer',
      
        headerStyle: { width: "30%" },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
           
              <div>
              
                  {row.reportGroup}
               
              </div>
           
          );
        },
      },
      {
        dataField: '',
        text: 'Action',


        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="btn-group">
              <Dropdown
                onClick={(e) => { e.stopPropagation(); }}
                className="more-action"
                >
                <Dropdown.Toggle
                  className="btn-outline-primary btn btn-primary-white more"
                  id={"dropdown-more" + rowIndex}

                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey={"addReport"} 

onClick={() => {
  this.setState({ ispopupshow: true,reportId:row.reportid,reportName:row.reportName });
}}

                  >Add to My Reports </Dropdown.Item>
                  <Dropdown.Item
                    eventKey={"comments"}
                    onClick={() => {
                      this.setState({ showDetailedReport: true,reportId:row.reportid,reportName:row.reportName ,reportGroup:row.reportGroup });
                    }}
                  >
                    Run Report{" "}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          );
        },
      },
      // {
      //   dataField: "",
      //   text: "Action",

      //   formatter: (cell: any, row: any, rowIndex: any) => {
      //     return (
      //       <div className="btn-group">
      //         <Dropdown
      //           onClick={(e) => {
      //             e.stopPropagation();
      //           }}
      //           className="more-action"
      //         >
      //           <Dropdown.Toggle
      //             className="btn-outline-primary btn btn-primary-white more"
      //             id={"dropdown-more" + rowIndex}
      //           >
      //             <svg
      //               width="20"
      //               height="20"
      //               viewBox="0 0 20 20"
      //               fill="none"
      //               xmlns="http://www.w3.org/2000/svg"
      //             >
      //               <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
      //               <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
      //               <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
      //             </svg>
      //           </Dropdown.Toggle>
      //           <Dropdown.Menu>
                  // <Dropdown.Item
                  //   eventKey={"comments"}
                  //   onClick={() => {
                  //     this.setState({ showDetailedReport: true,reportId:row.reportid,reportName:row.reportName });
                  //   }}
                  // >
                  //   Run Report{" "}
      //             </Dropdown.Item>
      //             <Dropdown.Item eventKey={"comments"}>Delete </Dropdown.Item>
      //           </Dropdown.Mfenu>
      //         </Dropdown>
      //       </div>
      //     );
      //   },
      // },
    ];
   
    const expandSplitRow = {
      onlyOneExpanding: false,
      parentClassName: "parent-expand-foo",
      renderer: (row: any, rowIndex: any) => renderItemTableChild(row, rowIndex),
      showExpandColumn: true,
      // expandByColumnOnly: true,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <div></div>;
        }
        return <div></div>;
      },
      expandColumnRenderer: ({ expanded }) => {

        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };


    const renderItemTableChild = (mainrow, rowIndex) => {
       
      let splitDetail: any = [];
      splitDetail = this.state.data.filter(
        (x) => x.reportGroup === mainrow.reportGroup && x.rowno!=1
      );
    

      const columnCOAChild = [
        {
          dataField: "srno",
          text: "Sr No.",
          hidden: false,
          editable: false,
        
          headerStyle: { width: "10%" },
          headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
          formatter: (cell: any, row: any, rowIndex: any) => {
            return <div>{rowIndex + 1}</div>;
          },
        },
  
        {
          dataField: "reportName",
          text: "Report Variant",
          sort: true,
          classes:"cursor-pointer",
          searchable: this.state.isReportName,
          headerStyle: { width: "25%" },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
            
                <div>
               
                    {row.reportName}
                  
                </div>
              
            );
          },
        },
        {
          dataField: "reportDescription",
          text: "Report Description",
          sort: true,
         
          headerStyle: { width: "40%" },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
             
                <div>
                
                    {row.reportDescription}
                 
                </div>
             
            );
          },
        },
        {
          dataField: '',
          text: 'Action',
  
  
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <div className="btn-group">
                <Dropdown
                  onClick={(e) => { e.stopPropagation(); }}
                  className="more-action"
                  >
                  <Dropdown.Toggle
                    className="btn-outline-primary btn btn-primary-white more"
                    id={"dropdown-more" + rowIndex}
  
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                      <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                      <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                    </svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey={"addReport"} 
  
                    onClick={() => {
                      this.setState({ ispopupshow: true,reportId:row.reportid,reportName:row.reportName });
                    }}
  
                    >Add to My Reports </Dropdown.Item>
                    <Dropdown.Item
                      eventKey={"comments"}
                      onClick={() => {
                        this.setState({ showDetailedReport: true,reportId:row.reportid,reportName:row.reportName,reportGroup:row.reportGroup  });
                      }}
                    >
                      Run Report{" "}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            );
          },
        }
      ];

      return splitDetail.length > 0 ? (
        <div className="height-72" style={{ paddingTop: "15px", paddingBottom: "15px", paddingLeft: "5%" }}>
          <BootstrapTable
            id="tblCOAchild"
            keyField="reportid"
            data={splitDetail}
            rowEvents={this.rowEvents}
            columns={columnCOAChild}
          />
        </div>
      ) : null;
    };

    const { showSlideout } = this.state;

    return (
      <section>
        <div
          className="details-drawer"
          style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
          // className="back-drop1  viewer-panel1 opacity "
        >
          <div
            className="back-drop1  viewer-panel1 opacity "
            style={{ minWidth: "25%", maxWidth: "100%", height: "100vh" }}
            /*  className="back-drop1  viewer-panel1 opacity " */
            onClick={() => this.closeSlideOut()}
          ></div>

          <Container
            fluid
            ref={this.parentScroll}
            className="body-sec viewer-panel back-drop p-2"
            style={{ minWidth: "75%", width: "792px", maxWidth: "100%"}}
          >
            <div className="page-heading underline d-flex  ">
              Gallery
              <div className="d-flex flex-row ml-auto">
                <button
                  type="button"
                  className="btn btn-primary cross"
                  onClick={this.closeSlideOut}
                  style={{ marginLeft: "10px" }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                      fill="#ffffff"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div
              className="scroll"
            >
         

          
                  <ToolkitProvider
                    keyField="id"
                    data={this.state.data.filter(x=>x.rowno==1)}
                    columns={qualitycolumns}
                    search
                  >
                    {(props) => (
                      <div>
                        <section className="d-flex flex-wrap gap-3 pb-2" style={{marginTop:"20px"}}>
                          <div className="position-relative">
                            <SearchBar {...props.searchProps} />
                          </div>

                          { <div>
                            <Form.Label>
                              <SingleDropdownListWithoutSearch
                                itemList={this.state.selectOptions}
                                handleSelectedItem={this.handleSelectedItem1.bind(
                                  this,
                                  1
                                )}
                                defaultItem={this.state.selectedcolumn}
                                defaultText={"Select Filter"}
                                defaultName={this.state.selectedcolumn}
                                controlID="1"
                                id={"ddlselected"}
                              />
                            </Form.Label>
                          </div> }
                        </section>
                        <section className="pb-3" style={{marginLeft:"5px"}}>
                          <div className="table-responsive divbottom">
                            <div className="ml-auto transactions-list">
                              <BootstrapTable
                                {...props.baseProps}
                                rowEvents={this.rowEvents}
                                  keyField="reportGroup"
                                expandRow={expandSplitRow}
                                pagination={paginationFactory(options)}
                              />
                            </div>
                          </div>
                        </section>
                      </div>
                    )}
                  </ToolkitProvider>
                  <div className="height-172">

<br></br>
<br></br>

</div>
                  </div>
               
          </Container>



          {this.state.ispopupshow && (
                 <div
               //className="d-flex align-items-center pos-fxd2 index-1000"
               >
                 <div className="react-confirm-alert-overlay confirm-modal undefined">
                  <div className="react-confirm-alert">
                  <div className="react-confirm-alert-body">
                        <div>
                          <div className="d-flex">
                            <h1 style={{ paddingTop: "6px!important" }}> Add to My Reports </h1>
                            <div className="header-popout ml-auto">
                              <button
                                type="button"
                                className="btn wht-bg cross"
                                onClick={this.MyCancel}
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                                    fill="#84888C"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Label className="font-semibold">
                          {this.state.reportName}
                          </Form.Label>



                          <Form.Control
                            tabIndex={this.state.tabEnable}
                            id="txtTemplateName"
                            type="text"
                            placeholder="Enter Report Name"
                            value={this.state.MyReportName}
                            onChange={(e: any) => this.handleChange(e, "reportname")}
                            //autoComplete="off"
                            //disabled={disable}
                            title=""
                          />
                        </Form.Group>
                        <br></br>
                        <div className="react-confirm-alert-button-group">
                          <button
                            type="button"
                            className="btn"
                            onClick={() => this.MyCancel()}
                          >
                            No
                          </button>

                          <div className="upload-link">
                            <button type="button" className="btn"
                              onClick={() => this.MyReportSave()}
                              disabled={this.state.MyReportName.trim() === ''}
                            >
                              Save
                            </button>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

          {(this.state.isSpinner || this.state.isSaveSpinner) && (
            <div className="loader-spinner d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="success" />
            </div>
          )}

          {/* {this.state.showDetailedReport && <DetailedReport closeSlideout={()=>{this.setState({showDetailedReport:false});}} />} */}
          {/* <DetailedReport
          show={this.state.showDetailedReport}
          reportId={this.state.reportId}
          ReportName={this.state.reportName}
          closeSlideOut={this.closeSlideOut2}
          //onHide={()=>{this.setState({showDetailedReport:false});}}
        /> */}


{this.state.showDetailedReport && (
              <DetailedReport
                closeSlideout={() => {
                  this.setState({ showDetailedReport: false });
                }}
                reportId={this.state.reportId}
                ReportName={this.state.reportName}
               // id={this.state.id}
                closeSlideOut={this.closeSlideOut2}
                reportGroup={this.state.reportGroup}
              />
            )}
        </div>
      </section>
    );
  }
}
