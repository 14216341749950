import * as React from "react";
import { Container, Dropdown, Accordion, Card, Button ,Form,Spinner,} from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import { CustomerService } from "../../Common/Services/CustomerService";
import { CustomerMasterSlidout } from "../../Modules/Masters/CustomerMasterSlidout";
import { SingleDropdownListWithoutSearch } from "../../Common/Components/SingleDropdownListWithoutSearch";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
const { SearchBar } = Search;
import paginationFactory from 'react-bootstrap-table2-paginator';
import { COAMasterSlidout } from "./COAMasterSlideout";
import _ from "lodash";


  
export class COAMaster extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
         
          htmlItem: [],
          htmlErrorItem: [],
          htmlWarningItem: [],
          searcResult: [],
          coainfo:{},
          selectedcolumn: "All",
          selectOptions: [
            { id: 'All', name: 'All'},
            { id: 'COA', name: 'COA' },
            { id: 'Name', name: 'Name' },
            { id: 'Account Type', name: 'Account Type' },
            { id: 'Main Group', name: 'Main Group' },
            { id: 'Sub Group', name: 'Sub Group' },
          ],
          data:[{
           coa:"C1018",
           name:"ABC Enterprises",
           accountType:"Asset",
           mainGroup:"Taruna",
           subGroup:"7686",
           status:"Active"
          },{
            coa:"C1019",
            name:"XYZ",
            accountType:"Liability",
            mainGroup:"Taruna",
            subGroup:"7686",
            status:"Active"
           },{
            coa:"C1020",
            name:"BUFFALO",
            accountType:"Revenue",
            mainGroup:"Taruna",
            subGroup:"7686",
            status:"Inactive"
           }]
        };
        
     
      }
      
      componentDidMount() {
        let requestlist = { ...this.state.requestlist };
        this.getCOAMaster(requestlist);
      }

      handleSelectedItem1 = (control: any, id: any) => {
        let searcResult1 = Object.assign(
          [],
          JSON.parse(JSON.stringify(this.state.searcResult))
        );
    
        let chkisstatus = id == "Status" ? true : false;
        let chkisAccountType = id == "Account Type" ? true : false;
        let chkismaingroup = id == "Main Group" ? true : false;
        let chkisname = id == "Name" ? true : false;
        let chkissubgroup = id == "Sub Group" ? true : false;
        let chkcoa = id == "COA" ? true : false;
    
        if (id == "All") {
          chkisstatus = true;
          chkisAccountType = true;
          chkismaingroup = true;
          chkisname = true;
          chkissubgroup = true;
          chkcoa = true;
        }
    
    
        this.setState({
          searcResult: [], selectedcolumn: id, isstatus: chkisstatus, isaccountType : chkisAccountType, ismainGroup: chkismaingroup,
          isname: chkisname, issubGroup: chkissubgroup, iscoa:chkcoa
        },
          () => {
            this.setState({ searcResult: searcResult1 });    
          }
    
    
        );
    
      };
 
      getCOAMaster = (requestlist: any) => {

      };

  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      this.setState({ showSlideout: true ,coainfo:row,isSpinner:false});  
    },
  };
  
  showNewSlideOut = () => {
    this.setState({ showSlideout: true ,coainfo:{},isSpinner:false});  
  };

  closeSlideOut = () => {
    this.setState({ showSlideout: false, isSpinner:false, });
    let requestlist = { ...this.state.requestlist };
    this.getCOAMaster(requestlist);
};


  render() {
    const customTotal = (from, to, size) => (
      <span className="text-muted">
        Showing {from} to {to} of {size} entries
      </span>
    );
    const options = {

      showTotal: true,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '10', value: 10
      },
      {
        text: '25', value: 25
      },
      {
        text: '50', value: 50
      },

      {
        text: 'All', value: this.state.searcResult.length
      }] // A numeric array is also available. the purpose of above example is custom the text
    };
    const {
      
      showSlideout,
      
  } = this.state;

  const columns = [
    {
      searchable: this.state.iscoa,
      dataField: 'coa',
      text: 'COA',
      sort: true,
    },
   
    {
      searchable: this.state.isname,
      dataField: 'name',
      text: 'Name',
      sort: true,
    },
    {
      searchable: this.state.isaccountType,
      dataField: 'accountType',
      text: 'Account Type',
    },
    {
      searchable: this.state.ismainGroup,
      dataField: 'mainGroup',
      text: 'Main Group',
    },

    
    {
      searchable: this.state.issubGroup,
      dataField: 'subGroup',
      text: 'Sub Group',
    },

    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      headerStyle: { width: '200px' },
      headerClasses: 'text-center',
      classes: 'text-center',
      
      searchable: this.state.isstatus,
      //searchable:this.state.isdueDate,
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          
          <>
         
          <EllipsisWithTooltip placement="bottom">
            
              

                <span className={ row.status==="Active" ? "badge badge-pill badge-primary color-green" : "badge badge-pill badge-primary color-red"}> {row.status} </span>


            </EllipsisWithTooltip>
      
      
            </>

        );
    },
    },
  ];
    return (
      <>
        <React.Fragment>



          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex">

            Chart of Accounts
              <div className="action-group d-flex flex-row ml-auto">
                <Button onClick={this.showNewSlideOut}>Add COA</Button>
              </div>
            </div>

            <section className="mt-3">
            <ToolkitProvider
        keyField="id"
        data={this.state.data}
        columns={columns}

        search
      >
        {
          props => (


            <div >
              <section className="d-flex flex-wrap gap-3 pb-2">
                <div className="position-relative">
                  <SearchBar {...props.searchProps} />
                </div>

                <div>
                  <Form.Label>
                    <SingleDropdownListWithoutSearch
                      
                      itemList={this.state.selectOptions}
                      handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}
                      defaultItem={this.state.selectedcolumn}
                      defaultText={"Select Filter"}
                      defaultName={this.state.selectedcolumn}
                      controlID="1"
                      id={"ddlselected"}

                    /></Form.Label>
                </div>

              </section>
              <section className="pb-3">
                <div className="table-responsive divbottom"  >
                  <div className="ml-auto transactions-list">
                  <BootstrapTable
                    {...props.baseProps}
                    rowEvents={this.rowEvents}
                    keyField="rowId"
                    pagination={paginationFactory(options)}

                  />
                  </div>
                </div>
            

              </section>
            </div>

          )
        }
</ToolkitProvider>

          </section>

{(showSlideout &&

<COAMasterSlidout
closeSlideOut={this.closeSlideOut}
coainfo={this.state.coainfo}
id={this.state.id}
>
</COAMasterSlidout>)}
          </Container>
       </React.Fragment>
      </>
    );
  }
}